import { getEmployeeByMyoctansId, getProjects } from '@/api/resources'
import Loading from '@/components/Loading'
import globalContext from '@/context'
import { isQueryLoading } from '@/helpers'
import { useReports, useTasks } from '@/hooks'
import { useQuery } from '@tanstack/react-query'
import { stubTrue } from 'lodash-es'
import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const FilledForms: FC<{ path: string; type?: ReportType }> = p => {
	const { t } = useTranslation()
	const [state] = useContext(globalContext)
	const { status: reportsStatus, fetchStatus: reportsFetchStatus, data: reports } = useReports()
	const { status: tasksStatus, fetchStatus: tasksFetchStatus, data: tasks } = useTasks()
	const {
		status: projectsStatus,
		fetchStatus: projectsFetchStatus,
		data: projects,
	} = useQuery({
		queryKey: ['projects'],
		queryFn: getProjects
	})
	const [employee, setEmployee] = useState<Api.Employee>()
	const isReport = (reportType: string) => {
		return ['driver', 'blaster', 'driller', 'shooting_log', 'time_material', 'driller_prod'].includes(reportType)
	}

	useEffect(() => {
		if (state?.user?.id) {
			getEmployeeByMyoctansId(state?.user?.id).then((employee: Api.Employee) => {
				setEmployee(employee)
			})
		}
	}, [])

	if (isQueryLoading(reportsStatus, reportsFetchStatus) || isQueryLoading(tasksStatus, tasksFetchStatus) || isQueryLoading(projectsStatus, projectsFetchStatus) || !employee) {
		return <Loading />
	}

	const filteredReports = reports?.filter(p.type ? r => r.form_type === p.type : stubTrue)
				.filter(r => isReport(r.form_type))
				.filter(r => r.employee_id === employee.id) ?? []

	if (filteredReports.length > 0) {
		return (
			<ol class='grid grid-cols-2 gap-4'>
				{filteredReports
					.map(r => (
						<li>
							<a
								href={`/reports/${r.form_type}/${r.id}/${p.type ? '' : 'disabled'}`}
								class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
							>
								<div class='mb-auto font-medium text-gray-800'>
									{r.form_type == 'driller_prod' ? projects?.find(project => project.id == r.project_id)?.name : tasks?.find(t => t.id === r.task_id)?.name ?? '-'}
								</div>
								{!p.type && <div class='text-sm text-gray-600'>{t(`routes:${r.form_type}`)}</div>}
								<div class='mt-2 text-right text-sm text-gray-600'>
									{DateTime.fromISO(r.date_in).toISODate()}
								</div>
							</a>
						</li>
					))}
			</ol>
		)
	} else {
		return (
			<h1>{t('forms:no_filled_forms')}</h1>
		)
	}
}

export default FilledForms

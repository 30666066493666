import { postReport } from '@/api/reports'
import Btn from '@/components/forms/Btn'
import Checkbox from '@/components/forms/Checkbox'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import Input from '@/components/forms/Input'
import Radio from '@/components/forms/Radio'
import { ProjectWithTaskSelect, TaskForProjectSelect } from '@/components/forms/FuzzySelect'
import { API_DATETIME_FMT, INPUT_DATETIME_FMT } from '@/constants'
import globalContext from '@/context'
import { useSubmit } from '@/hooks'
import { DateTime } from 'luxon'
import { useContext, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const yesNoChoices =  (t: (key: string) => string): {[index: string]:any } => ({
    1: t('yes'),
    0: t('no')
});

type Props = {
	path: string
}

type Take5Form = {
	'form[0]': boolean
	'form[1]': boolean
	'form[2]': boolean
	'form[3]': boolean
	'form[4]': boolean
	'form[5]': boolean
	'form[6]': boolean
	'form[7]': boolean
	'form[8]': boolean
	'form[9]': boolean
	'form[10]': boolean
	'form[11]': boolean
	'form[12]': boolean
	'form[13]': boolean
	'form[14]': boolean
	'form[15]': string[]
	'form[16]': string[]
	'form[17]': boolean
}

const Take5: FC<Props> = ({path: string, ...p}) => {
	const { t } = useTranslation('take5')
	const [_, actions] = useContext(globalContext)
	const now = DateTime.now()
	const executionTime = fmtDate(now)
	const [projectId, setProjectId] = useState(0)
	const yesNo = yesNoChoices(t)
	const [ formState, setFormState ] = useState<Take5Form>({
		'form[0]': yesNo.no,
		'form[1]': false,
		'form[2]': false,
		'form[3]': false,
		'form[4]': false,
		'form[5]': false,
		'form[6]': false,
		'form[7]': false,
		'form[8]': false,
		'form[9]': false,
		'form[10]': false,
		'form[11]': false,
		'form[12]': false,
		'form[13]': false,
		'form[14]': false,
		'form[15]': [],
		'form[16]': [],
		'form[17]': false
	})

	const isRiskPresent: boolean = (formState['form[0]'] ||
							formState['form[1]'] as boolean ||
							formState['form[2]'] as boolean ||
							formState['form[3]'] as boolean ||
							formState['form[4]'] as boolean) ?? false

	const { handleSubmit, submitDisabled } = useSubmit({
		submit: postReport,
		async process(fd: FormData) {
			for (let i = 0; i <= 14; i++) {
				const value = fd.get(`form[${i}]`)
				if (value === null) {
					fd.append(`form[${i}]`, 0)
				}
			}
        },
		async onSuccess(res) {
			actions.toastInfo(t('toasts:form_submitted'));
			setTimeout(() => {
				history.pushState({}, '', '/');
				location.href = '/';
			}, 1000);
		},
		onError(err) {
			if (err instanceof TypeError) {
				actions.toastError(t('toasts:network_error'))
			} else {
				actions.toastError(t('toasts:generic_error'))
			}
			return false;
		}
	})
	
	const handleChange = (value: string | boolean | number, name: string) => {
		const newFormState = {...formState}
		newFormState[name] = value
		setFormState(newFormState)
	}

	const projectSetterWrapper = (refId: number) => {
		setProjectId(refId)
	}

	return (
		<>
			<h1>{t(`routes:take5`)}</h1>
			<form onSubmit={handleSubmit}>
				<input type="hidden" name={'type'} value="take5"/>
				<input type='hidden' name='version' defaultValue='2' />
				<ProjectWithTaskSelect
					required
					name='project_id'
					label={t('project')}
					setref={projectSetterWrapper}
				/>
				<TaskForProjectSelect
					required
					name='task_id'
					label={t('task')}
					localfilterkey={projectId}
				/>
				<Input
					className='span6'
					required
					max={now.toFormat(INPUT_DATETIME_FMT)}
					defaultValue={executionTime ?? now}
					type='datetime-local'
					name='date_in'
					label={t('forms:execution_time')}
				/>

				<hr />

				<fieldset>
					<legend>{t('think_through_task')}</legend>

					<fieldset>
						<div class="flex">
							<p class="grow w-6/12">{t('is_something_different')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[0]"
									choices={yesNo}
									checked={formState['form[0]'] ? "1" : "0"}
									onChange={() => handleChange(!formState['form[0]'], 'form[0]')}
								/>
							</div>
						</div>
					
						<div class="flex">
						<p class="mr-2 grow">{t('are_there_risk')}</p>
						</div>

						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[1]"
								value="1"
								label={t('above')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[1]']}
								onChange={() => handleChange(1, 'form[1]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[2]"
								value="1"
								label={t('below')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[2]']}
								onChange={() => handleChange(1, 'form[2]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[3]"
								value="1"
								label={t('in_front_behind')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[3]']}
								onChange={() => handleChange(1, 'form[3]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[4]"
								value="1"
								label={t('inside')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[4]']}
								onChange={() => handleChange(1, 'form[4]')}
							/>
						</div>
						<div hidden>
							<p class="text-red-600 text-sm">{t('note_the_hazard')}</p>
						</div>
						
					</fieldset>

					<fieldset disabled={!isRiskPresent} >
						<div class="flex">
							<p class="grow w-6/12">{t('is_there_job_procedure')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[5]"
									choices={yesNo}
									checked={formState['form[5]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[5]'], 'form[5]')}
								/>
							</div>
						</div>

						<div class="flex">
							<p class="grow w-6/12">{t('do_i_understand_work')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[6]"
									choices={yesNo}
									checked={formState['form[6]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[6]'], 'form[6]')}
								/>
							</div>
						</div>

						<div class="flex">
							<p class="grow w-6/12">{t('is_permit_required')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[7]"
									choices={yesNo}
									checked={formState['form[7]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[7]'], 'form[7]')}
								/>
							</div>
						</div>
					
						

						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[8]"
								value="1"
								label={t('confined_space')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[8]']}
								onChange={() => handleChange(1, 'form[8]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[9]"
								value="1"
								label={t('hot_work')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[9]']}
								onChange={() => handleChange(1, 'form[9]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[10]"
								value="1"
								label={t('excavation')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
								checked={formState['form[10]']}
								onChange={() => handleChange(1, 'form[10]')}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[11]"
								value="1"
								label={t('vegetation_clearing')}
								class={'text-base mr-2 normal-case'}
								checked={formState['form[11]']}
								onChange={() => handleChange(1, 'form[11]')}
								labelFirst={true}
							/>
						</div>
						<div class="pr-3 flex justify-end">
							<Checkbox
								name="form[12]"
								value="1"
								label={t('other')}
								checked={formState['form[12]']}
								onChange={() => handleChange(1, 'form[12]')}
								class={'text-base mr-2 normal-case'}
								labelFirst={true}
							/>
						</div>
						<br />

						<div class="flex">
							<p class="grow w-6/12">{t('involve_hazardous_materials')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[13]"
									choices={yesNo}
									checked={formState['form[13]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[13]'], 'form[13]')}
								/>
							</div>
						</div>

						<div class="flex">
							<p class="grow w-6/12">{t('appropriate_tools_equipment')}</p>
							<div class="justify-end pr-3 pt-1">
								<Radio
									name="form[14]"
									choices={yesNo}
									checked={formState['form[14]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[14]'], 'form[14]')}
								/>
							</div>
						</div>
						
					</fieldset>

					<DynamicFieldset 
						disabled={!isRiskPresent}
						label={t('look_for_hazards')}
						make={ i => (
							<Input 
								name={`form[15][${i}]`}
								label={t('hazard')}
							/>

						)}
					>
					</DynamicFieldset>

					<DynamicFieldset 
						disabled={!isRiskPresent}
						label={t('take_the_precautions')}
						make={ i => (
							<Input 
								name={`form[16][${i}]`}
								label={t('how_to_control_hazard')}
							/>
						)}
					>	
						<br />
						<div class="flex" disabled={!isRiskPresent}>
							<p class="mr-2 grow">{t('can_i_perform_safely')}</p>
							<div class="justify-end pr-4 pt-1">
								<Radio
									name="form[17]"
									choices={yesNo}
									checked={formState['form[0]'] ? '1' : '0'}
									onChange={() => handleChange(!formState['form[17]'], 'form[17]')}
								/>
							</div>
						</div>
					</DynamicFieldset>

				</fieldset>
				<Btn submit>
					{t('forms:submit')}
				</Btn>
			</form>
		
		</>
	)
}

function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromFormat(dateStr, API_DATETIME_FMT) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT)
}

export default Take5

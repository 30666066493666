import { postReport, putReport } from '@/api/reports'
import Btn from '@/components/forms/Btn'
import globalContext from '@/context'
import { useReports, useSubmit } from '@/hooks'
import { useContext, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import ProjectHeader from '@/components/forms/ProjectHeader'

type Props = {
	type: ReportType
	submitArgs?: Partial<Parameters<typeof useSubmit>[0]>
	report?: Api.Report
	disabled?: boolean
}

const Report: FC<Props> = p => {
	const { t } = useTranslation()
	const [, actions] = useContext(globalContext)
	const formRef = useRef<HTMLFormElement>()
	const [isComplete, setIsComplete] = useState(p.report?.form?.is_complete ?? '0')
	const { handleSubmit, submitDisabled } = useSubmit({
		process: p.submitArgs?.process,
		submit: (p.report?.id === undefined || p.type != 'driller_prod') ? postReport : putReport,
		async onSuccess(res) {
			if (p.submitArgs?.onSuccess) {
				await p.submitArgs?.onSuccess(res);
			}

			actions.toastInfo(t('toasts:form_submitted'));
			setTimeout(() => {
				history.pushState({}, '', '/reports');
				location.href = '/reports';
			}, 1000);
		},
		onError(err) {
			if (err instanceof TypeError) {
				actions.toastError(t('toasts:network_error'))
			} else {
				actions.toastError(t('toasts:generic_error'))
			}
			return false;
		}
	})
	const { data: reports } = useReports()

	const handleCompleteClick = (e: MouseEvent) => {
		e.preventDefault()
		const isConfirmed = window.confirm(t('driller:confirm_complete'))

		if (isConfirmed) {
			setIsComplete('1')
			setTimeout(() => {
				formRef.current?.requestSubmit()
			}, 100)
		}
	}

	return (
		<>
			<h1>{t(`routes:${p.type}`)}</h1>
			<form onSubmit={handleSubmit} ref={formRef}>
				<fieldset class='dummy' disabled={p.disabled}>
					{(!p.disabled && !p.report && p.type != 'driller_prod') && (
						<Btn
							class='justify-self-end'
							disabled={!reports?.filter(r => r.form_type === p.type).length}
						>
							<a href={`/reports/filled/${p.type}`}>{t('forms:copy_from_prev')}</a>
						</Btn>
					)}
					<input hidden name='type' defaultValue={p.type} />
					<input hidden name="id" value={p.report?.id} />
					{ p.type !== 'driller_prod' ? <ProjectHeader report={p.report} type={p.type} /> : '' }
					{p.children}
					{!p.disabled && (
						<Btn disabled={submitDisabled} submit>
							{p.type == 'driller_prod' ? t('forms:save_temporarily') : t('forms:submit')}
						</Btn>
					)}
					{
						p.type == 'driller_prod' ? (
							<input type="hidden" id="isComplete" name="form[is_complete]" value={isComplete} />
						) : ''
					}
					
				</fieldset>
			</form>
			{
				p.type == 'driller_prod' ? (
					<>
						<Btn disabled={submitDisabled} onClick={handleCompleteClick} data-complete={true} className={'button flex items-center justify-center gap-x-4 primary mx-auto my-2 px-8 py-3 bg-teal-500 text-white shadow-md disabled:bg-gray-400 disabled:shadow-none'}>
							{t('forms:complete')}
						</Btn>
					</>
					
				) : ''
			}
		</>
	)
}
export default Report

import { SummaryData } from "./DrillingSummary"
import { defaultHoleDescription } from "./MineDrilling"
import type { HoleDescription } from "./HoleDescriptionForm"
import type { DrillEquipment } from "./DrillEquipmentSelect"
import type { MatrixData } from "./Matrix"


export const initHolesArray = (cols: number, rows: number): HoleDescription[] => {
    const holesArray: HoleDescription[] = []
    for (let i = 0; i < cols * rows; i++) {
        holesArray.push({
            ...structuredClone(defaultHoleDescription),
            hole_number: String.fromCharCode(Math.ceil((i + 1) / cols) + 64) + ((i % rows) + 1)
        })
    }
    return holesArray
}

export const calculateSummary = (matrixData: MatrixData): SummaryData => {

    const calculatedProduction = matrixData.holeDescriptions.reduce((acc, hole) => {
        if (hole.is_remote) {
            return {
                drilled: acc.drilled,
                drilledRemote: acc.drilledRemote + (hole.drilled.use_for_calculations ? hole.drilled.value : (hole.drilled.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0)),
                redrilled: acc.redrilled,
                redrilledRemote: acc.redrilledRemote + (hole.redrilled.use_for_calculations ? hole.redrilled.value : (hole.redrilled.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0)),
                cleaned: acc.cleaned,
                cleanedRemote: acc.cleanedRemote + (hole.cleaned.use_for_calculations ? hole.cleaned.value : (hole.cleaned.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0))
            }
        } else {
            return {
                drilled: acc.drilled + (hole.drilled.use_for_calculations ? hole.drilled.value : (hole.drilled.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0)),
                drilledRemote: acc.drilledRemote,
                redrilled: acc.redrilled + (hole.redrilled.use_for_calculations ? hole.redrilled.value : (hole.redrilled.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0)),
                redrilledRemote: acc.redrilledRemote,
                cleaned: acc.cleaned + (hole.cleaned.use_for_calculations ? hole.cleaned.value : (hole.cleaned.value != 0 && hole.depth_planned.use_for_calculations ? hole.depth_planned.value : 0)),
                cleanedRemote: acc.cleanedRemote
            }
        }
    }, {
        drilled: 0,
        drilledRemote: 0,
        redrilled: 0,
        redrilledRemote: 0,
        cleaned: 0,
        cleanedRemote: 0
    })

    const realProduction = matrixData.holeDescriptions.reduce((acc, hole) => {
        return {
            drilled: acc.drilled + (hole.is_remote ? 0 : hole.drilled.value),
            drilledRemote: acc.drilledRemote + (hole.is_remote ? hole.drilled.value : 0),
            redrilled: acc.redrilled + (hole.is_remote ? 0 : hole.redrilled.value),
            redrilledRemote: acc.redrilledRemote + (hole.is_remote ? hole.redrilled.value : 0),
            cleaned: acc.cleaned + (hole.is_remote ? 0 : hole.cleaned.value),
            cleanedRemote: acc.cleanedRemote + (hole.is_remote ? hole.cleaned.value : 0)
        }
    }, {
        drilled: 0,
        drilledRemote: 0,
        redrilled: 0,
        redrilledRemote: 0,
        cleaned: 0,
        cleanedRemote: 0
    })
    
    return {
        calculatedProduction: calculatedProduction,
        realProduction: realProduction
    }
}

export const makeMatrixData = (report?: Api.Report): { matrixData: MatrixData, summaryComment: string } | undefined => {
    if (!report) {
        return undefined
    }
    const form = report.form
    const cols = form.matrix ? form.matrix.length : 0
    const rows = form.matrix ? form.matrix[0]?.length : 0
    const holeDescriptions: HoleDescription[] = []
    form.mine?.forEach((hole: any) => {
        holeDescriptions.push({
            hole_number: hole.hole_number,
            is_off_contract: hole.is_off_contract,
            trephine_number: hole.trephine_number,
            diameter: parseFloat(hole.diameter),
            hammer: hole.hammer,
            angle: parseFloat(hole.angle),
            depth_planned: {
                value: parseFloat(hole.depth_planned.value),
                use_for_calculations: hole.depth_planned.use_for_calculations
            },
            drilled: {
                value: parseFloat(hole.drilled.value),
                use_for_calculations: hole.drilled.use_for_calculations
            },
            redrilled: {
                value: parseFloat(hole.redrilled.value),
                use_for_calculations: hole.redrilled.use_for_calculations
            },
            cleaned: {
                value: parseFloat(hole.cleaned.value),
                use_for_calculations: hole.cleaned.use_for_calculations
            },
            depth_measured: parseFloat(hole.depth_measured),
            broken_rocks: parseFloat(hole.broken_rocks),
            casing_meters: parseFloat(hole.casing_meters),
            is_remote: hole.is_remote ? true : false,
            is_water: hole.is_water ? true : false,
            is_pre_cutting: hole.is_pre_cutting ? true : false,
            is_geotechnical_limit: hole.is_geotechnical_limit ? true : false,
            result: hole.result,
            comment: hole.comment,
            matrix_input: hole.matrix_input,
            is_collapsed: true,
            is_hole_number_changed: true
        })
    })

    const matrixData: MatrixData = {
        cols: cols,
        rows: rows,
        burden: form.burden ?? 0,
        spacing: form.spacing ?? 0,
        holeDescriptions: holeDescriptions.length == 0 ? initHolesArray(cols, rows) : holeDescriptions,
    }

    return { matrixData: matrixData, summaryComment: form.summary.comment }
}

export const makeDrillEquipment = (report?: Api.Report): DrillEquipment | undefined => {
    if (!report) {
        return undefined
    }
    const drill = report.equipment?.at(0)
    const compressor = report.equipment?.at(1)
    const drillEquipment: DrillEquipment = {
        drill: {
            equipment_id: drill?.id,
            start_time: drill?.pivot?.start_time ?? 0,
            end_time: drill?.pivot?.end_time ?? 0
        },
        compressor: {
            equipment_id: compressor?.id,
            start_time: compressor?.pivot?.start_time ?? 0,
            end_time: compressor?.pivot?.end_time ?? 0
        },
    }
    return drillEquipment
}

import classNames from 'classnames'
import type { StateUpdater } from 'preact/hooks'
import { FC } from 'preact/compat'

type Props = { 
	label: string
	class: string
	checked: boolean
	name?: string
	onChange?: StateUpdater<boolean>
	labelFirst? : boolean
	value?: string
}

const Checkbox: FC<Props> = ({ label, class: className, checked, name, ...p }) => {

	return (
		<div class={classNames('items-baseline gap-2', className)}>
			<label>
				{
					p.labelFirst ? label : ''
				}
				<input type='checkbox' {...p}  value={p.value} checked={checked} name={name} onChange={(e) => p.onChange && p.onChange(e.target?.checked)}/>
				{
					p.labelFirst ? '' : label
				}
			</label>
		</div>
		
	)
}
export default Checkbox

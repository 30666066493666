import { AddressSelect, ProjectWithTaskSelect } from '@/components/forms/FuzzySelect'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import Input from '@/components/forms/Input'
import { INPUT_DATETIME_FMT } from '@/constants'
import { DateTime } from 'luxon'
import { StateUpdater, useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DrillEquipmentSelect from '@/pages/reports/driller_prod/DrillEquipmentSelect'
import { makeDrillEquipment } from '@/pages/reports/driller_prod/utils'
import Radio from './Radio'
import globalContext from '@/context'
import { getProjectsWithTask } from '@/api/resources'
import { useQuery } from '@tanstack/react-query'

export type DrillHeaderForm = {
	project: Api.Project
	quarter: string
}

type Props = {
	report?: Api.Report
	type?: ReportType
	disabled?: boolean
	units: string
	drilllingType: string
	setUnits: StateUpdater<string>
	setDrillingType: StateUpdater<string>
	handleProjectMatch: (project: object | undefined) => void
}

const DrillHeader: FC<Props> = ({ report: r, ...p }) => {
	const { t, i18n } = useTranslation('forms')
	const [ state, actions ] = useContext(globalContext)
	const [ headerForm, setHeaderForm ] = useState<DrillHeaderForm>()
	const { status: projectsStatus, fetchStatus: projectsFetchStatus, data: projects } = useQuery({
		queryKey: ['projects-tasks'],
		queryFn: getProjectsWithTask,
	})

	useEffect(() => {
		if (r && projects != undefined) {
			const project = projects?.find(p => p.id == r.project_id)
			if (!project) {
				return
			}
			setHeaderForm({...headerForm, project: project, quarter: r.form.quarter ?? t('driller:day')})
		}
	}, [r, projects])

	const handleProjectMatch = (project: object | undefined) => {
		p.handleProjectMatch(project)
	}

	const handleQuarterChange = (quarter: string) => {
		setHeaderForm({...headerForm, quarter: quarter})
	}

	const workQuarters: Record<any, string> = {
        day: t('driller:day'),
        night: t('driller:night'),
    }

	const now = DateTime.now()
	const executionTime = r?.date_in ? DateTime.fromISO(r.date_in) : now
	const defaultCollapsed = r != undefined && !p.disabled

	return (
		<>
			<CollapsibleFieldset title={t('general_information')} defaultCollapsed={defaultCollapsed}>
				<Input
                    className='span6'
                    required
                    max={now.toFormat(INPUT_DATETIME_FMT)}
                    defaultValue={r?.date_in ? DateTime.fromISO(r?.date_in).toFormat(INPUT_DATETIME_FMT) : now.toFormat(INPUT_DATETIME_FMT)}
                    type='datetime-local'
                    name='date_in'
                    label={t('driller:date_in_long')}
                />
				<ProjectWithTaskSelect
					required
					name='project_id'
					selectedId={headerForm?.project ? headerForm?.project.id : undefined}
					label={t('project')}
					onMatch={handleProjectMatch}
				/>
				<AddressSelect
					readonly
					defaultValue={r?.form?.work_execution_address}
					selectedId={headerForm?.project?.supplier_work_execution_address_id}
					name='form[work_execution_address]'
					label={t('work_execution_address')}
					prefered='name'
				/>
				<Input
					name='form[drilling_block]'
					value={r?.form?.drilling_block}
					label={t('driller:drilling_block')}
				/>
				<Input
					name='form[ditch_number]'
					value={r?.form?.ditch_number}
					label={t('driller:ditch_number')}
				/>
				<fieldset>
                <legend>{t('driller:quarter')}</legend>
					<Radio
						name='form[quarter]'
						checked={headerForm?.quarter ?? 'day'}
						choices={workQuarters}
						onChange={(e) => handleQuarterChange(e)}
					/>
					
				</fieldset>
				<DrillEquipmentSelect defaultValue={makeDrillEquipment(r)}/>

				<label>{t('driller:drilling_type')}
                    <select name={`form[drill_type]`} onChange={(e) => p.setDrillingType(e.target?.value)}>
                        {/* <option value=''>{t('forms:select_an_option')}</option> */}
                        <option selected={p.drilllingType == 'mine'} value='mine'>{t('driller:mine')}</option>
                        {/* <option value='quarry'>{t('quarry')}</option> */}
                    </select>
                </label>
				<div>
					<label>{t('units')}</label>
					<select name={`form[units]`} onChange={(e: Event) => p.setUnits((e.target as HTMLInputElement).value )}>
						<option selected={p.units == 'meters'} value='meters'>{t('meters')}</option>
						<option selected={p.units == 'feet'} value='feet'>{t('feet')}</option>
					</select>
				</div>
			</CollapsibleFieldset>
			<hr />
		</>
	)
}

export default DrillHeader

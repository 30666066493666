import { useTranslation } from 'react-i18next'
import Input from '@/components/forms/Input'

export type SummaryData = {
    calculatedProduction : {
        drilled: number
        drilledRemote: number
        redrilled: number
        redrilledRemote: number
        cleaned: number
        cleanedRemote: number
    }
    realProduction : {
        drilled: number
        drilledRemote: number
        redrilled: number
        redrilledRemote: number
        cleaned: number
        cleanedRemote: number
    }
    comment?: string
}

type Props = { 
    summary: SummaryData
    units: string
    comment?: string
}

const DrillingSummary: FC<Props> = ({ summary, ...p }) => {
	const { t } = useTranslation('driller')

	return (
		<>
            <fieldset>
                <legend>{t('summary')}</legend>
                <h2 className="text-gray-500">{t('calculated_production')}</h2>

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][drilled]'
                    value={summary.calculatedProduction.drilled}
                    label={t('drilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][redrilled]'
                    value={summary.calculatedProduction.redrilled}
                    label={t('redrilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][cleaned]'
                    value={summary.calculatedProduction.cleaned}
                    label={t('cleaned')}
                />
                
                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][drilled_remote]'
                    value={summary.calculatedProduction.drilledRemote}
                    label={t('drilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][redrilled_remote]'
                    value={summary.calculatedProduction.redrilledRemote}
                    label={t('redrilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][calculated_production][cleaned_remote]'
                    value={summary.calculatedProduction.cleanedRemote}
                    label={t('cleaned_remote')}
                />

                <h3 className="text-gray-500">{t('real_production')}</h3>
                
                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][drilled]'
                    value={summary.realProduction.drilled}
                    label={t('drilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][redrilled]'
                    value={summary.realProduction.redrilled}
                    label={t('redrilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][cleaned]'
                    value={summary.realProduction.cleaned}
                    label={t('cleaned')}
                />
                
                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][drilled_remote]'
                    value={summary.realProduction.drilledRemote}
                    label={t('drilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][redrilled_remote]'
                    value={summary.realProduction.redrilledRemote}
                    label={t('redrilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][real_production][cleaned_remote]'
                    value={summary.realProduction.cleanedRemote}
                    label={t('cleaned_remote')}
                />
                
                <Input
                    className='span6'
                    type='text'
                    defaultValue={p.comment}
                    name='form[summary][comment]'
                    label={t('irregularity_comment')}
                />
            </fieldset>
        
		</>
	)
}
export default DrillingSummary